import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPhone/Install/PoE/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "iphone-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iphone-app-installationsassistent",
        "aria-label": "iphone app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPhone App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-ip-kamera-poe-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-ip-kamera-poe-models",
        "aria-label": "hinzufügen einer neuen ip kamera poe models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen IP Kamera (`}<em parentName="h3">{`PoE Models`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/81315/iPhone_Wizard_Installation_PoE_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADKklEQVQ4y41Uy24jRRTtP2HJgg1iwzeyQAiQ+JuRhpEmMI4nOEoQE03sxG477qerquvZj+qD6rbbbqMgaOmoqm7dPnXfkZIaRV6g63o0jYf3IDStJ1melxBCwbkGWZqibQedvsdJN4DzCnlxQJTwFuuihjQdet8jSTJC2/Zomw5SN3hhLVLRIS4c6sYjywosl0/YbLZYr2N6zLoWm7JBxIUFEw6ispDSgjEFxiTtK5INd0FPVDXJ07TEy0uG3S6llXMFrWvSi6rKYIQQhkgCwn5EdZSzg0Sec8Rxgs1mj+02OZFmGSPdKLCKI8mUaHoOVqbpAdfXfxBmsxvc3NxhsbjHx4+3dJ7PF1it4mDhmew1jIR5zjCf3xLJ7e2feP/+A66uZri+XuDu7i+Sf/78fCacunjh7j8ekLKmOAdXt9uULA8PjvfR+edL118LwXSvVE2gpE3kUcia0Q5FfsBuNwQ5jveE4XULWZkL96uJRSPxeB8J02HPW2RVi0zUaNsWXdeh6zzBew9T+1MZZdkBSVJQTENmHx5WWK02Z5eltkgKjpIrZAcBKSWRhK8P7YAedddDqsG14MGnuMIuYXj49Ig3b97i3bsrKqdgfaScR6k9pOuA3pN13ndEGggDiPAYq2Dh87ZAkjIsH58wu4+xuF8iTQpKWMQrjZecIysFOBdgjINzDqUUvO+J2JLL7hT4LC1PiVjHGfb78uyykhZaheAOrTfFuVTGjFtwblCUklZRORwOCoxrqpAhKf9Sf68hZLsxBq21tDbaoDUWrTHQcii9/+yU0bJAVjKL+ZPD70uH2WrAh6XDb48O69RCSfP/CMOkqbXBYu3w9U8eX37v8c3PHt/+4vHVDx5ffOfx468N4Mw4baYE4/SZWHiUB+Ln1GKVDFjuj2tikR8GLyJqOWFPY2p6vsRAbpSBnWA8y+P4i6YWidNc1BcZHrJ8vBuHrTDgY3aD7lEvqqoKVisUukdpgNoaaG3QNA2MMXCuhnMOWmsoWYGZHpkC2sZBSkWNEGq2aVrSibpjV3S+p8LO8xxFkUMITnK667rT3vc9RCVRkF4BxtjpPqx/A3oHiM+HSlC0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6174eea45669cb8d7dc60efb1a53d1a7/e4706/iPhone_Wizard_Installation_PoE_d01.avif 230w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/d1af7/iPhone_Wizard_Installation_PoE_d01.avif 460w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/7f308/iPhone_Wizard_Installation_PoE_d01.avif 920w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/e1c99/iPhone_Wizard_Installation_PoE_d01.avif 1380w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/6e1e4/iPhone_Wizard_Installation_PoE_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6174eea45669cb8d7dc60efb1a53d1a7/a0b58/iPhone_Wizard_Installation_PoE_d01.webp 230w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/bc10c/iPhone_Wizard_Installation_PoE_d01.webp 460w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/966d8/iPhone_Wizard_Installation_PoE_d01.webp 920w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/445df/iPhone_Wizard_Installation_PoE_d01.webp 1380w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/87a8c/iPhone_Wizard_Installation_PoE_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6174eea45669cb8d7dc60efb1a53d1a7/81c8e/iPhone_Wizard_Installation_PoE_d01.png 230w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/08a84/iPhone_Wizard_Installation_PoE_d01.png 460w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/c0255/iPhone_Wizard_Installation_PoE_d01.png 920w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/b1001/iPhone_Wizard_Installation_PoE_d01.png 1380w", "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/81315/iPhone_Wizard_Installation_PoE_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6174eea45669cb8d7dc60efb1a53d1a7/c0255/iPhone_Wizard_Installation_PoE_d01.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Menü `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie den `}<strong parentName="li">{`Installationsassistenten`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie finden den `}<strong parentName="li">{`QR-Code`}</strong>{` in der `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Internet_Access/"
        }}>{`WebUI`}</a>{` (A), auf dem Etikett Ihrer Kamera (B) oder auf der Verpackung abgedruckt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/81315/iPhone_Wizard_Installation_PoE_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACNklEQVQ4y52Vyc8hURTFqwwfhZiHGBaWFv7/bS97owwLWyEhQSuzoqhyOud2V7WhKfkkJ56XvN87d3iXoqhBKKEoFEV5KU2LQW+1sFoaOBwOOJ1OOJ/PIsuyRIZhYDabQVFCEajxLBQ18BIY0zT8+NnD0gSs0xHr9QaDwUA0mUwEzIso5Z0zVVX/AGMaWnobC2MF0zzAtm0cj0dxSneO43h6C/QcxmLotNtYGoYAHj/X69WTB0yn0ygWi6J8Po9CoYBsNvs3hxq63S42mw3m8zkul4scpqNbmAdMJBIiOonH43fiHoG9Xg/r9Rr7/f4J8gTkwXfhSsidjgApXyDdBQIBrxC3coFt5nC5xGq1+m+oT0C3oq8cErhYLKRlfIE8QIeZTEaUy+VEvCQajd6FTJdsm7dAVpguK5UKSqWSqFarSW6TyaQAWeXtdiu999gqT8B6vS4wfjebTTQaDW+vXC57bWOapjyxj6sciUQkZPYhQ33M4W63k8b2BfJAKpWSsAmiuOYe13ToVnk6nfrn8PaluL+5ZmHcCwlky3yUQ4puboGEcc97y52OhMyn5+uQITFEVrdarYpYDAKZV7fKBHLK+Dr8+vqSgxRz5q55USgU8kJm23D2+Rblk/Gl67q0jDu+vgV0n6Pbh5zI2+0OF9uWPHKM8ftbA7al67DPFuYbCwsTuDoXHExT3PIiwtn4d8BgMIhwOCzi+t+flIZ+vy+HbcfB/JeB4XCI0WiE8XgseaU7uv0NFvBA6jtC+EUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/e4706/iPhone_Wizard_Installation_PoE_d02.avif 230w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/d1af7/iPhone_Wizard_Installation_PoE_d02.avif 460w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/7f308/iPhone_Wizard_Installation_PoE_d02.avif 920w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/e1c99/iPhone_Wizard_Installation_PoE_d02.avif 1380w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/6e1e4/iPhone_Wizard_Installation_PoE_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/a0b58/iPhone_Wizard_Installation_PoE_d02.webp 230w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/bc10c/iPhone_Wizard_Installation_PoE_d02.webp 460w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/966d8/iPhone_Wizard_Installation_PoE_d02.webp 920w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/445df/iPhone_Wizard_Installation_PoE_d02.webp 1380w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/87a8c/iPhone_Wizard_Installation_PoE_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/81c8e/iPhone_Wizard_Installation_PoE_d02.png 230w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/08a84/iPhone_Wizard_Installation_PoE_d02.png 460w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/c0255/iPhone_Wizard_Installation_PoE_d02.png 920w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/b1001/iPhone_Wizard_Installation_PoE_d02.png 1380w", "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/81315/iPhone_Wizard_Installation_PoE_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b3a666a9fc00e8fdaf6c82e4cbb5226/c0255/iPhone_Wizard_Installation_PoE_d02.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Wählen Sie Ihr Kameramodell, z.B. `}<strong parentName="li">{`IN-8003FullHD`}</strong></li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c075eda277e1425a1b2253a38fb01d41/81315/iPhone_Wizard_Installation_PoE_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC/0lEQVQ4y31Uy7LcNBD1P1HFApb8IAWELPgSFiwgxQdQVEhRNzf3jscz47eelmRZ9kmp5fH4PpLFKVmto364TyvTakDbtFjmGdMUEMJC8D59N00HITRG51GVFaZphl9584yNx7lE2/bILmxC0Y0oWIByQFM3qKqGSJMPUMOIM5tQiUA8NwbMYUHXMeR5kQL4AGs98s4j48IiggmLtpM4FSWKokTfSyhlIZWFkInDhYOUluyc6xtntUVeJqVBhJIWfa9Q14zQdZKIQqTzK+I+2ex2frMZZGJ3kCI5wt4mdnjuSO5tKUP74kA+u/g8k68h21+IJbetoJXz4YmzfZBYgdYj4VrNFVuGVdnhdKpQVz3hfK5pfz2P++Pxgjy/4OGhwMePB9zdPeDTp5yaeMwvtG4OL5cWBV04o7y0aGpGTlJkg8F4ODdhHAO0tmBMou8FadTaEXpwMGZMJce0Y4YxQhWzO1XouyhUTsG0dmiFRcMktFZQSmEYBmitCZgnPDDg/wY3h03DkB/O+Pef9zgVFWUZdXb7Zxpt26BtW0LXdbT2fY85BPRmQaWW5PD606OT+7vHJOydDkm0JuDEPEENFuPoYJ2Dcw5YFgi7QLnlpWxiVrHLe6nEbjLGcH9/j2NxQlmWqOsaTVOjqipoJXGSQM6RJkUIi55HGDDhaAzjd7TxTfAxgMKgLc3uOKYGRcQ5jk2LyKIzqw0wvYJg6IxvFTgKzrl5gifCNtrgQ2Hx018ev7zzePt3wpt3Hj/+6fH+aDenr43bfuxI2N4Y/PHB4ds3M777dcYPvyV8/3bGNz/P+P0/h8kYMG4puy+N6DYpW8l+xbhi3bvBrI6uL87ewctsqct8bQLjKRPGnzYllWpXGEhhtmxfPA77yOnyAC6GzRa7mx7RG0+sPPEKL5NSwQ4a7bCgd8AUBWsdQggwxmAcRxLvMBjSGzMLGgPMYSLbsiw0ht5PtGbzPCMizAsuZYXHx0fC+XzGsqxnISDxQhwK1G1HnMPhgKIo4L3feJ8BKs2OTJaU2hYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c075eda277e1425a1b2253a38fb01d41/e4706/iPhone_Wizard_Installation_PoE_d03.avif 230w", "/en/static/c075eda277e1425a1b2253a38fb01d41/d1af7/iPhone_Wizard_Installation_PoE_d03.avif 460w", "/en/static/c075eda277e1425a1b2253a38fb01d41/7f308/iPhone_Wizard_Installation_PoE_d03.avif 920w", "/en/static/c075eda277e1425a1b2253a38fb01d41/e1c99/iPhone_Wizard_Installation_PoE_d03.avif 1380w", "/en/static/c075eda277e1425a1b2253a38fb01d41/6e1e4/iPhone_Wizard_Installation_PoE_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c075eda277e1425a1b2253a38fb01d41/a0b58/iPhone_Wizard_Installation_PoE_d03.webp 230w", "/en/static/c075eda277e1425a1b2253a38fb01d41/bc10c/iPhone_Wizard_Installation_PoE_d03.webp 460w", "/en/static/c075eda277e1425a1b2253a38fb01d41/966d8/iPhone_Wizard_Installation_PoE_d03.webp 920w", "/en/static/c075eda277e1425a1b2253a38fb01d41/445df/iPhone_Wizard_Installation_PoE_d03.webp 1380w", "/en/static/c075eda277e1425a1b2253a38fb01d41/87a8c/iPhone_Wizard_Installation_PoE_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c075eda277e1425a1b2253a38fb01d41/81c8e/iPhone_Wizard_Installation_PoE_d03.png 230w", "/en/static/c075eda277e1425a1b2253a38fb01d41/08a84/iPhone_Wizard_Installation_PoE_d03.png 460w", "/en/static/c075eda277e1425a1b2253a38fb01d41/c0255/iPhone_Wizard_Installation_PoE_d03.png 920w", "/en/static/c075eda277e1425a1b2253a38fb01d41/b1001/iPhone_Wizard_Installation_PoE_d03.png 1380w", "/en/static/c075eda277e1425a1b2253a38fb01d41/81315/iPhone_Wizard_Installation_PoE_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c075eda277e1425a1b2253a38fb01d41/c0255/iPhone_Wizard_Installation_PoE_d03.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Select if you are using PoE or the external power supply to power your camera.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/81315/iPhone_Wizard_Installation_PoE_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAADB0lEQVQ4y41Vy47VOBDNz7BgC18wfB9iw27mM0YaxOzZtBDS0PTl0ajpm/vMw7mx41fi2GdUlZvQIBqwdFQVxzk+VWVXMq0NqqpGCBHDMGIcE2P2q0pASg1rLfa7Lbz3cM6h73sMw7D4QggURYFsdwrYNz0TxggURcUYR2AMEZ3pQWsOJ491qWGdRwgBZVkiz3PeYOh7aGNwW1lkrXRolUdVnVDXLY5HgeOxZp/mus5DKochRCQAvu/RdR2klGjbln1SH2PEGBOyrnOoqha7XYE83+NwqLHbldhuvz4TKSnRukOnFLTWDCKaLROOIzJS9PbtChcXb/Dq1QXb16//W+zl5QdWLUSD1WqFzWaDeaSUFptShHYBmZQWbWuw3R6x2UwgdWRJqVIOtIZUzGGST3mkosygZ98PyOgDgjEDtO4XO4PIyNa1YIXX19cwxrAyCnNSN0H7MBE2QuHLTY58vccmPzDJXdCaEMYlPMoVKaK5SWFg8kg5pITXtcTHD5+ZdL8rF5K7GMfIhGMYcGoEGlFDyRPqqoDR3XkzfA2ZCK8u3/NRmfN6OmnI1vB7UhAiIF2C9AnCJPaVB8MMVKA0FYXDbhQfneIo+JiQTxsIofh5OmdgMt1jISIQsR0wESrlmezLzQbXn25YFRHQJmXZLOHPIQPpHkz5XRRWtUK+OaKqJPtF2aIsW0j5bQ6niuKHoJHRB6azgHeIzmDQCkOnMBoN9NM7ec7h3cN838h0Z5GXDi/eeby86vHv+wkvVz3+eeexLoj0W4U/JQzW4sWVx8NnEY+eRzz5K+KPPyMeP4948DTi70uP6Cw3h98iVIpyaFE1DmXjUIgJ5NOc+q4ovySUZ0IK/UeYNvy+KPeDizJhumbt+bldfLsc7N8ZS8hzV5HSMGiOeuUMureJ7mtMGM+9j8B3+E6TyJTq4IxGbRIaD4Se/hmeF1Obov8FNVdjLYxWONmE2gIpBp6jQd2HmgWtz+YdqH0fjgVub28Z+/2em+bciWdLNSkrwWvW6zW22y13nLlj/w+yxJqzF8zX7AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bf329e5f0ce25878f212a6ab387bbf1/e4706/iPhone_Wizard_Installation_PoE_d04.avif 230w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/d1af7/iPhone_Wizard_Installation_PoE_d04.avif 460w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/7f308/iPhone_Wizard_Installation_PoE_d04.avif 920w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/e1c99/iPhone_Wizard_Installation_PoE_d04.avif 1380w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/6e1e4/iPhone_Wizard_Installation_PoE_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5bf329e5f0ce25878f212a6ab387bbf1/a0b58/iPhone_Wizard_Installation_PoE_d04.webp 230w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/bc10c/iPhone_Wizard_Installation_PoE_d04.webp 460w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/966d8/iPhone_Wizard_Installation_PoE_d04.webp 920w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/445df/iPhone_Wizard_Installation_PoE_d04.webp 1380w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/87a8c/iPhone_Wizard_Installation_PoE_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5bf329e5f0ce25878f212a6ab387bbf1/81c8e/iPhone_Wizard_Installation_PoE_d04.png 230w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/08a84/iPhone_Wizard_Installation_PoE_d04.png 460w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/c0255/iPhone_Wizard_Installation_PoE_d04.png 920w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/b1001/iPhone_Wizard_Installation_PoE_d04.png 1380w", "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/81315/iPhone_Wizard_Installation_PoE_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5bf329e5f0ce25878f212a6ab387bbf1/c0255/iPhone_Wizard_Installation_PoE_d04.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Wählen Sie Ihre Kamera aus der Liste aus. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/81315/iPhone_Wizard_Installation_PoE_d05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClUlEQVQ4y61VTW/TQBD1D0LigjgBRw4cQPwuDqhIRSoHhFAPVbnwD5D4kEDiQisubZMmEPLRxLFjez9ie/ehN45dN0ppDoz0tPbs27cz4511sFjEmIxHsNbCGIPlcimon8fjMeZRBK0Uhn8GsNY0c3meN89hGGIymSD4HRr8mipobVAUBUajEYbDoZCtMYgThUFoMIos+hcZtLHCm81m6Pf7wqNgphR6U40gLxwKBzHuliQJFouFPNO89yhKJyh9xdFaI8syxHHcvJNXOo+Ai5gGCVEUiRhHkpVSyPNlJewcjNHin8/nwqlHgqKuLGtBK0Sm2+12cXJygul0KkSK1sZ3znc6HZydneH09FRA32AwkFIEXMCd+FKnuMkYAaPnxjV3kwWMhCjLUsTaoI+gxXGEXq+H7nkfyixROiAvWV8Pm5dSY64JWFSmzF3XBdtwzgGe4ozOr8HJ6PhR+FUZIT/9dSm7lasfA0/2gYevgcdvgaf7wKM3wINXwMGPVcoUYh2vS5nnzPkqkrn22P3q8fxzNe5989j54vHso8f3QcUJmKoscq6JqC3a9t1kzERqmKapnMM0zZoDTeNG83kkNW4OufPSCES5Gom6LAHJWaaQZgoJBe1SJom8KGWOwlcjx0aI4Obg66/Xdt2c8krQ49O5x+0XHnd2Pe7vedzbA+6+BG7teHzoVOJMb5s6SoTjFDg8Ag6Pgfc/K7w7Bg6OgGFyGeA2QQbbkLbM9jLl+urZBL86g//qojYC/GcLeKArsDNccyHQt97LDc9d5bW5AQ+10RlC5RFZoMwtjLVC4pVV/2OU1lBZgkh7zDQv3EJ8NLYuO478oN6F9RqNJ3JFEfyvuLVIqn4HLqahcPhP4cVaty7n/wIL1KhcaUND4gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a89a34abfee6cd35da9126e5f11b1c7d/e4706/iPhone_Wizard_Installation_PoE_d05.avif 230w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/d1af7/iPhone_Wizard_Installation_PoE_d05.avif 460w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/7f308/iPhone_Wizard_Installation_PoE_d05.avif 920w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/e1c99/iPhone_Wizard_Installation_PoE_d05.avif 1380w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/6e1e4/iPhone_Wizard_Installation_PoE_d05.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a89a34abfee6cd35da9126e5f11b1c7d/a0b58/iPhone_Wizard_Installation_PoE_d05.webp 230w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/bc10c/iPhone_Wizard_Installation_PoE_d05.webp 460w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/966d8/iPhone_Wizard_Installation_PoE_d05.webp 920w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/445df/iPhone_Wizard_Installation_PoE_d05.webp 1380w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/87a8c/iPhone_Wizard_Installation_PoE_d05.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a89a34abfee6cd35da9126e5f11b1c7d/81c8e/iPhone_Wizard_Installation_PoE_d05.png 230w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/08a84/iPhone_Wizard_Installation_PoE_d05.png 460w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/c0255/iPhone_Wizard_Installation_PoE_d05.png 920w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/b1001/iPhone_Wizard_Installation_PoE_d05.png 1380w", "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/81315/iPhone_Wizard_Installation_PoE_d05.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a89a34abfee6cd35da9126e5f11b1c7d/c0255/iPhone_Wizard_Installation_PoE_d05.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/97e397c4f0a470e4d719964ec438043d/8efc2/iPhone_Wizard_Installation_PoE_d06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAArCAYAAAB4pah1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAD90lEQVRIx+1XzW7jVBj1O8ALILEBhhdAvAbPMStmtgjNCiFmdrCfl0CoXbSLImAxtE2TOHGapInzn9iO7Xv9d33Q+RwnaemwQGyQeqWTe7+/cz/buc6JVRQlgiBCs9mG605RlkCWFchzI+A6TXPBwX+Ix7FGp9PF3cgV2ypNCR3HGA36WC0W9wjruR7c/OFmSiW4G44wn85QFAbWOjK4XQOTENgo3NudBFonsO0ObLuLKIphDPbxOofj3QTYxAbWJszhzHN0ZhlG6wxZmiMhkkw6iCKFVsvG1VUDnhcICWOp5ORIkwxKZ7h2M3hRDivcKqhIIVEJVKSx3WoEgdrPYZhA6wJJQvJE/GHIWLxfc05ijSjUsMbjOa6v27hu2Oh2B+h2+2g02jLb9i1arS5suyfrZrOLRsPGcOii1XIk5jhDtNsObm46uLubwoqkqwhhGEOpVMCnTmy3MXw/RBgqWdO32QSy5tNlDdd1HmerKAoMh0M0m01cXV3Bcbow5vBkHxvtdhu2bePy8hK9Xg83NzcIgkBiVlmWIGmSJIjjGEmSSoCkj4H5ec7vJB9MKrVcc2bc4sdgMEC/34fruuh0OtIxB4uPwaG1huM4ksPuuGY9m2GKdOj7PjzPE+discBqtRKCx7pjV8vlUmo2m82+ln5jyopwPB5jOp1iNpvJ5byvu3rmmEwmckW8Mm7AZuQe8mM+XwgZE7hTfQ8fktZdc2ZX6/Va6tgl6+gXQl4i22fSfLFEpDRMCeRFidz8HYUpsVpvsN4wf4EwDOWhCiF35M1l67e3PczHfSCLdhdm3oMSq+kQC7ePrt3GeOwiiqKKkEeHx4pQqsC7kcGvtxkunAQXvVRw1qlw4VQ2Y78PDX4bGPiRQaozRFEqx9DieV3xNZPE+ObnFNbzEtbLEtYLVPga+PAV8MErwHqJg585z0t89TZDFsfwfCVn3+LB9nwNoxXe/pHi4+8Nnr0x+Ox1hU9+MPjiR4MvfzJ49trg053/8zcGH31n8O0vGTKl4Ad8YWgS8pwqMdK4Ciw3GivvgNm6wrFv6WmsvaqmrudsBTtmGv5uDh8gCiuEj8T8o/pdh/cd/waHDneE/yWeCJ8InwifCP/PhPxxqUVm/cL1A31kqyMR+s+2VatU/gzWgThU0JHa21Sux/FKuSaPNKIqbeN5vogkkS4mxzzI4SwLWVMq1pKjln5xTKKtyBLaVGT0yb8AJrFgPp/vxFAJZ1Xiz0m1ZlFNWOkaIyqBeqYSpqXYlCOiHKi2uAPFjghHU8JXBoGmjuFfiEx0C8E4SZhPsJa2UkrAbq2DRKvVVSGWO7rD6ekpzs7O9jg/P8fJyYlI4mOVWw92+BflYMhp/8qu9QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/97e397c4f0a470e4d719964ec438043d/e4706/iPhone_Wizard_Installation_PoE_d06.avif 230w", "/en/static/97e397c4f0a470e4d719964ec438043d/d1af7/iPhone_Wizard_Installation_PoE_d06.avif 460w", "/en/static/97e397c4f0a470e4d719964ec438043d/ec170/iPhone_Wizard_Installation_PoE_d06.avif 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/97e397c4f0a470e4d719964ec438043d/a0b58/iPhone_Wizard_Installation_PoE_d06.webp 230w", "/en/static/97e397c4f0a470e4d719964ec438043d/bc10c/iPhone_Wizard_Installation_PoE_d06.webp 460w", "/en/static/97e397c4f0a470e4d719964ec438043d/712de/iPhone_Wizard_Installation_PoE_d06.webp 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/97e397c4f0a470e4d719964ec438043d/81c8e/iPhone_Wizard_Installation_PoE_d06.png 230w", "/en/static/97e397c4f0a470e4d719964ec438043d/08a84/iPhone_Wizard_Installation_PoE_d06.png 460w", "/en/static/97e397c4f0a470e4d719964ec438043d/8efc2/iPhone_Wizard_Installation_PoE_d06.png 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/97e397c4f0a470e4d719964ec438043d/8efc2/iPhone_Wizard_Installation_PoE_d06.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Klicken Sie auf `}<strong parentName="li">{`Weiter`}</strong>{`, um fortzufahren.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      